import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const footer = ({ className, children }) => (
  <footer role="contentinfo" className={className}>
    {children}
  </footer>
)

footer.propTypes = {
  className: PropTypes.string.isRequired,
}

const Footer = styled(footer)`
`

export default Footer
