import styled from 'styled-components'

import { backgroundColor, defaultColor } from '../utils/colors'

const Grid = styled.div`
  margin: -8px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  background-color: ${backgroundColor};
  color: ${defaultColor};

  & > * {
    grid-column: 1;
  }
`

export default Grid;
