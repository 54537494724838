import styled from 'styled-components'

import UnstyledBrand from './UnstyledBrand'

const Brand = styled(UnstyledBrand)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 17.5rem;

  img {
    width: 100%;
  }
`

export default Brand;
