import React from 'react'
import Layout from '../components/layout'
import Card from '../components/Card'
import { StaticQuery, graphql } from 'gatsby'

const IndexPage = ({data}) => (
  <Layout>
    <StaticQuery
      query={graphql`
        query ThoughtsQuery {
          allThoughtsJson {
            edges {
              node {
                html,
                highlighted
              }
            }
          }
        }
      `}
      render={data => {
        const {allThoughtsJson} = data
        const {edges} = allThoughtsJson
        const thoughts = edges.map(
          (edge) => ({
            "html": edge.node.html,
            "highlighted": edge.node.highlighted || false,
          })
        )

        return (
        <>
          {thoughts.map(
            (thought, index) => (
              <Card key={index} highlighted={thought.highlighted}>
                <p dangerouslySetInnerHTML={{ __html: thought.html }}></p>
              </Card>
            )
          )}
        </>)
      }}
    />
  </Layout>
)

export default IndexPage
