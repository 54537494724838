import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

export const UnstyledCard = (props) => {

  const { className, children, highlighted, bordered } = props

  return <section
    className={classnames(className, {"highlighted": highlighted})}
    highlighted={highlighted}
    bordered={bordered}
  >
    {children}
  </section>
}

UnstyledCard.propTypes = {
  highlighted: PropTypes.bool,
  bordered: PropTypes.bool,
  children: PropTypes.node.isRequired
}

export default UnstyledCard;
