import React from 'react'
import styled from 'styled-components'

import Brand from './Brand'
import LargeText from './Text'
// import MenuButton from './MenuButton'
import { backgroundColor, defaultColor } from '../utils/colors'

const header = ({ className, siteTitle }) => (
  <header id="header" className={className}>
    <Brand />
    <LargeText>Rope bondage and self‑suspension notes</LargeText>
    {/* <MenuButton id="menu" type="button">Menu</MenuButton> */}
  </header>
)

const Header = styled(header)`
  font-size: 1.8rem;
  background-color: ${backgroundColor};
  background-color: #3a348f;
  color: ${defaultColor};
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  text-align: center;

  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  padding: 1rem;
  min-height: 60vh;

  & img {
    margin: 0;
    padding: 1rem 0 2rem;
    filter: invert(100%);
  }
`

export default Header
