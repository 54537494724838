import styled from 'styled-components'

import { defaultColor } from '../utils/colors'
import UnstyledCard from './UnstyledCard'

const Card = styled(UnstyledCard)`
  background-color: transparent;
  color: ${defaultColor};
  padding: 2rem 1rem;
  position: relative;
  margin: 2rem auto;
  max-width: 27.5rem;
  font-family: 'Raleway', sans-serif;

  & > * {
    margin: 0;
    padding: 0;
  }

  &::before {
    left: 50%;
  }
  &::after {
    left: 50%;
  }

  &:first-child {
    margin-top: 2rem;
    z-index: 2;
  }

  &:first-child,
  &.highlighted {
    border: 1px dotted #bbb;
    background-color: white;
    border-radius: 4px;
    margin: 2rem auto;
    padding: 3rem 3rem;
    max-width: 34rem;
  }

  &.highlighted {
    border: 1px solid #ccb285 !important;
    color: black;
  }
  &.highlighted::before {
    height: 1rem;
    top: -1rem;
  }
  &.highlighted::after {
    height: 1rem;
    bottom: -1rem;
  }


  &:nth-child(3n+1) {
    text-align: left;
  }

  &:nth-child(3n+2) {
    text-align: center;
  }

  &:nth-child(3n) {
    text-align: right;
  }

  &:nth-child(1) {
    &::before {
      content: none;
    }
    &::after {
      height: 1rem;
      bottom: -1rem;
    }
  }

  &:nth-child(2) {
    &.highlighted::before {
      height: 1rem;
      top: -1rem;
    }
    &::before {
      height: 3rem;
      top: -1rem;
    }
  }

  &:last-child {
    &::after {
      content: none;
    }
  }

  &::before,
  &::after {
    content: " ";
    border-width: 0 0 0 1px;
    display: block;
    width: 0%;
    height: 3rem;
    margin: 0 auto;
    position: absolute;
    top: -1rem;
  }

  &::after {
    bottom: -1rem;
    top: auto;
  }
`

export default Card;
