import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const main = ({children, className}) => (
  <section role="main" className={className}>
    {children}
  </section>
)

main.propTypes = {
  children: PropTypes.node.isRequired,
}

const Main = styled(main)`
  background-color: inherit;
  padding: 0 1rem;
`

export default Main;
