import React from 'react'
import logo from '../../images/suspendedthoughts.png'

export const UnstyledBrand = ({ className }) => (
  <span className={className}>
    <img src={logo} alt="Suspended Thoughts" />
  </span>
)

export default UnstyledBrand;
