import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Grid from './Grid'
import Footer from "./Footer"
import Header from './header'
import Main from './Main'

const Layout = ({ children, headless }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { 'http-equiv': 'Content-Security-Policy', content: 'upgrade-insecure-requests' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Grid>
          { !headless ? <Header /> : <div></div> }
          <Main role="main">
            {children}
          </Main>
          <Footer>
          </Footer>
        </Grid>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headless: PropTypes.bool,
}

export default Layout
